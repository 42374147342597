<template>
  <div>
    <div class="table-responsive-sm">
      <table class="table b-table table-fixed">
        <thead>
          <tr>
            <th>Nama</th>
            <th>User</th>
            <th>Deadline</th>
            <th>Harga</th>
            <th>Waktu Pembuatan</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoadingComponent">
            <td
              colspan="6"
              class="text-center"
            >
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <tr
            v-for="item in resultData.data"
            v-else
            :key="item.id"
          >
            <td>
              {{ item.name ? item.name : '-' }}
            </td>
            <td>
              <router-link :to="`/user-detail/${item.user.uuid}`">
                {{ item.user.name ? item.user.name : '-' }}
              </router-link>
            </td>
            <td>
              {{ item.due_date }}
            </td>
            <td>
              {{ item.price | formatAmount }}
            </td>
            <td>
              {{ item.created_at ? item.created_at : '-' }}
            </td>
            <td>
              <b-dropdown
                variant="info"
                size="sm"
              >
                <template #button-content>
                  Action
                </template>
                <b-dropdown-item
                  v-if="checkPermission('show lowongan')"
                  @click="goToDetail(item.uuid)"
                >
                  <feather-icon
                    icon="EyeIcon"
                    class="mr-75"
                  />
                  Detail Lowongan
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="checkPermission('delete lowongan')"
                  @click="deleteItem(item.uuid)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-75"
                  />
                  Hapus Lowongan
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
          <tr v-if="result.total == 0 && !isLoading">
            <td
              colspan="6"
              class="text-center"
            >
              Data is empty.
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-if="resultData.total > 0"
        class="m-1"
      >
        <div class="row">
          <div class="col mb-1">
            <small>Showing {{ resultData.from }} to {{ resultData.to }} from {{ resultData.total }}</small>
          </div>
          <div class="col">
            <pagination
              :data="resultData"
              :limit="4"
              align="right"
              @pagination-change-page="getData"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import {
  BSpinner,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BImg,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

export default {
  components: {
    BSpinner,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BImg,
  },
  /* eslint-disable vue/require-default-prop */
  props: {
    result: {
      type: Object,
    },
    isLoading: {
      type: Boolean,
    },
    getData: {
      type: Function,
    },
    deleteItem: {
      type: Function,
    },
    goToDetail: {
      type: Function,
    },
  },
  watch: {
    isLoading(value) {
      this.isLoadingComponent = value
    },
    result(value) {
      this.resultData = value
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      isLoadingComponent: true,
      resultData: {},
    }
  },
  methods: {

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
