<template>
  <b-card-code
    no-body
    title="Manajemen Lowongan"
  >
    <div class="dt_adv_search ml-1 mr-1">
      <div class="row">
        <div class="col-lg-12">
          <div class="form-row">
            <div class="col-lg-12">
              <div class="form-row mb-1 justify-content-start">
                <div class="col-lg-4 mb-1 mb-lg-0 align-self-end justify-content-end">
                  <input
                    v-model="filter.keyword"
                    type="text"
                    placeholder="Search"
                    class="form-control form-control-sm"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Vacancy
      :result="result"
      :is-loading="isLoading"
      :get-data="getData"
      :deleteItem="deleteItem"
      :goToDetail="goToDetail"
    />
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import Vacancy from '@/components/Vacancy/Vacancy.vue'

Vue.use(VuejsDialog)

export default {
  title() {
    return 'Manajemen Lowongan'
  },
  components: {
    BCardCode,
    Vacancy,
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      result: {},
      isLoading: false,
      validations: {},
      formPayload: Object,
      editId: null,
      categoryName: '',
      filter: {
        keyword: '',
      },
    }
  },
  created() {
    this.getData()
  },
  methods: {
    deleteItem(id) {
      this.$swal({
        title: 'Apa kamu yakin??',
        text: 'Apa kamu yakin akan menghapus lowongan ini?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`admin/vacancies/${id}`)
            .then(response => {
              this.getData(this.currentPage)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Lowongan berhasil dihapus',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
    getData(page = 1) {
      this.isLoading = true
      const queryParams = this.filter
      queryParams.page = page

      this.$http.get('/admin/vacancies', {
        params: queryParams,
      })
        .then(response => {
          this.result = response.data.data
          this.isLoading = false
        })
    },
    goToDetail(uuid) {
      this.$router.push(`/lowongan-detail/${uuid}`)
    },
  },
}
</script>
